// eslint-disable-next-line import/no-named-as-default
import authClient from "../../services/pingApi";
import styles from "./NoAccessLogoutButton.css";

const NoAccessLogoutButton = () => {
  const handleOnClick = () => {
    authClient.signOff("", ""); // privide token and state here for sign off
  };

  return (
    <button id="signOut" onClick={handleOnClick} className={styles.button} type="button">
      Sign out
    </button>
  );
};

export default NoAccessLogoutButton;
