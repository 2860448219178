const GENERIC_CONSTANTS = {
  SEARCH: "Search",
  LOGOUT_MESSAGE: "Are you sure you want to log out?",
  YES: "Yes",
  CANCEL: "Cancel",
  USERNAME: "Username",
  PASSWORD: "Password",
  LOGIN: "Login",
  LOGIN_SSO: "Login with SSO",
  SSO_TOKEN: "SSO Token",
  COUNTRY: "Country",
  STATE: "State",
  COUNTY: "County",
  CITY: "City",
  BACK: "Back",
  SAVE_CONTINUE: "Save & Continue",
  CREATE: "Create",
  DISCARD: "Discard and Leave",
  UPLOAD: "Upload",
  DOWNLOAD: "Download",
  DOWNLOAD_TEMPLATE: "Download Template",
  UPDATE: "Update",
  UPDATE_CONTINUE: "Update & Continue",
  SUBMIT: "Submit",
};

export default GENERIC_CONSTANTS;
