import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import ViewRegulations from "./containers/Regulations/ViewRegulations";
import AddRegulations from "./containers/Regulations/AddRegulations";
import Authx from "./containers/Authx";
import NoAccessPage from "./components/NoAccessPage";
import { AUTHX_LOGGGED_OUT } from "./services/utils.js/AuthxConstants";

dayjs.extend(isSameOrBefore);

const isPingSsoEnabled = true;

const router = createBrowserRouter([
  {
    path: "/",
    element: isPingSsoEnabled ? <Authx /> : <ViewRegulations isPingSsoEnabled={isPingSsoEnabled} />,
    children: [
      { path: "/", element: <ViewRegulations isPingSsoEnabled={isPingSsoEnabled} /> },
      {
        path: "/landing",
        element: <ViewRegulations isPingSsoEnabled={isPingSsoEnabled} />,
        children: [
          { path: "addRegulations", element: <AddRegulations /> },
        ],
      },
      { path: "/logout", element: <NoAccessPage noAccessReason={AUTHX_LOGGGED_OUT} /> },
    ],
  },
]);

const App = () => (
  <RouterProvider router={router} />
);

export default App;
