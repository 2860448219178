import { configureStore } from "@reduxjs/toolkit";
import loginTokenSlice from "./loginTokenSlice";

import regulationMaintenanceSlice from "./regulationMaintenanceSlice";

const store = configureStore({
  reducer: {
    loginToken: loginTokenSlice,
    regulationMaintenanceForm: regulationMaintenanceSlice,
  },
});

export default store;
