import { string } from "prop-types";
import styles from "./NoAccessPage.css";
import Header from "../Header";
import NoAccessLogoutButton from "../NoAccessLogoutButton";

const getNoAccessMessage = (noAccessReason) => {
  switch (noAccessReason) {
    case "Pending":
      return (
        <div className={styles.sign_in_Btn_postion}>
          <h1 className={styles.display_msg}>Signing you on...</h1>
        </div>
      );
    case "Error":
      return (
        <div className={styles.sign_in_Btn_postion}>
          <h1 className={styles.display_msg}>You failed to login. Please try again ...</h1>
        </div>
      );
    case "Logout":
      return (
        <div className={styles.sign_in_Btn_postion}>
          <h1 className={styles.display_msg}>You successfully logged out </h1>
        </div>
      );
    case "Denied":
    default:
      return (
        <div className={styles.sign_in_Btn_postion}>
          <h1 className={styles.display_msg}>No Access</h1>
        </div>
      );
  }
};

const NoAccessPage = ({ noAccessReason }) => (
  <div className={styles.parent_grid_container}>
    <Header>
      <NoAccessLogoutButton />
    </Header>
    <div className={styles.grid_container}>
      <div>
        {getNoAccessMessage(noAccessReason)}
      </div>
    </div>
  </div>
);

NoAccessPage.propTypes = {
  noAccessReason: string,
};

export default NoAccessPage;
