// eslint-disable-next-line no-unused-vars
import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import regulatedPartsTemplate from "../../resources/Regulated_Parts_Template.xlsx";
import GENERIC_CONSTANTS from "../../constants/Regulations/Generic";
import "./DownloadTemplateButton.css";

const DownloadTemplateButton = () => (
  <Tooltip title={GENERIC_CONSTANTS.DOWNLOAD_TEMPLATE} placement="top">
    <IconButton
      className="download-template-button"
      id="regulatedPartsDownloadTemplate"
      size="large"
      component="label"
    >
      <a
        aria-hidden="true"
        aria-disabled="true"
        href={regulatedPartsTemplate}
        download="your file name"
        className="download-anchor"
      >
        <FileDownloadIcon />
      </a>
    </IconButton>
  </Tooltip>
);

export default DownloadTemplateButton;
