import dayjs from "dayjs";

export const getFormattedDate = (dateVal) => {
  if (dayjs && typeof dayjs === "function") {
    return dayjs(dateVal).format("YYYY-MM-DD");
  }
  return dateVal;
};

export const mergeAndHandleRegulationParts = (existingParts = [], uploadedParts = []) => {
  if (!existingParts?.length && !uploadedParts?.length) {
    return [];
  }

  const uniqueParts = new Map();
  existingParts.forEach((existingPart) => {
    uniqueParts.set(existingPart.id, existingPart);
  });

  uploadedParts.forEach((each) => {
    const formattedPart = {
      ...each,
      id: `${each.lineAbbrev}-${each.partNo}`,
      obsoleteDate: each.obsoleteDate ? getFormattedDate(each.obsoleteDate) : null,
      expiredDate: each.expiredDate ? getFormattedDate(each.expiredDate) : null,
      bestClass: each.bestClass || null,
    };
    uniqueParts.set(formattedPart.id, { ...uniqueParts.get(formattedPart.id), ...formattedPart });
  });

  return Array.from(uniqueParts.values());
};
